import React from "react";

function Footer() {
    return (
        <footer className='page-footer'>
            <div className='sec-footer'>
            <div className='container'>
                <a href="./"><span>All Star 2022</span></a>
                <span>
                    <a href="mailto:contact@qatar2022.io">contact@qatar2022.io</a>
                </span>
                <ul className='social-icons'>
                    <li>
                    <a className='s-opensea' href="https://opensea.io/collection/all-star-2022" target="_blank" rel="noreferrer" title="Opensea">
                        <i className='qatar-icon-opensea'></i>
                    </a>
                    </li>
                    <li>
                    <a className='s-discord' href="https://discord.gg/9cQhYNKgJR" target="_blank" rel="noreferrer" title="Discord">
                        <i className='qatar-icon-discord'></i>
                    </a>
                    </li>
                </ul>
            </div>
            </div>
        </footer>
    )
}

export default Footer
