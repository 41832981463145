const FaqContents = 
[
    {
      "question": "Which country won FIFA World Cup 2022?",
      "answer": () => (
        <div>
          <strong>Argentina</strong> won the World Cup in Qatar 2022 after defeating France in a tense, nail-biting 4-2 penalty shootout. Their victory makes them one of the few teams to win the tournament three times: in 1978, 1986, and 2022.
        </div>
      )
    },
    {
      "question": "Who is the best player in FIFA 2022?",
      "answer": () => (
        <div>
          <strong>Messi</strong> collected the Golden Ball award as the best player of the tournament moments before lifting the FIFA World Cup 2022 trophy in Qatar. <strong>Kylian Mbappe</strong> and <strong>Luka Modric</strong> were among his challengers. Lionel Messi has won the Golden Ball award, presented to the best player of the FIFA World Cup 2022 in Qatar.
        </div>
      )
    },
    {
      "question": "Who has the most goals in FIFA 2022?",
      "answer": () => (
        <div>
          Who scored the most goals in the FIFA World Cup 2022? <strong>Kylian Mbappe</strong>, the France forward won the Golden Boot for scoring the most goals in the FIFA World Cup 2022 in Qatar. <strong>Lionel Messi</strong> finished second with seven goals, while his compatriot Julian Alvarez and Frenchman Olivier Giroud finished third.
        </div>
      )
    },
    {
      "question": "Who got the Golden Glove 2022?",
      "answer": () => (
        <div>
          <strong>Emiliano Martinez</strong> | Argentina | Golden Glove Award | FIFA World Cup Qatar 2022™
        </div>
      )
    },
    {
      "question": "Who got the best young player in FIFA 2022?",
      "answer": () => (
        <div>
          Argentina's <strong>Enzo Fernandez</strong>(Age of 22) is handed the trophy for the Best Young Player at the 2022 FIFA World Cup™️ in Qatar.
        </div>
      )
    },
    {
      "question": "What is an interesting fact about FIFA World Cup 2022?",
      "answer": () => (
        <div>
          There were <strong>172 goals scored at the 2022 World Cup</strong>, a new all-time competition record, surpassing the 171 scored in both 1998 and 2014. There were five penalty shootouts at the 2022 World Cup, the most of any single edition in the history of the competition.
        </div>
      )
    },
    {
      "question": "Why FIFA 2022 is special?",
      "answer": () => (
        <div>
          The FIFA World Cup 2022™ in Qatar is the first to be hosted in the Middle East and the most geographically compact since the inaugural edition of the tournament in 1930. The FIFA World Cup 2022™ is unique in many respects, with the type of opportunities and challenges related to sustainability being no exception.
        </div>
      )
    },
]

export default FaqContents
