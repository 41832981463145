import React, { useState, useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import WOW from 'wowjs';
import classNames from 'classnames';

import { animateFrom, hide } from './utils';

import FAQs from "./components/Accordion";
import Footer from './components/Footer';
import questionsAnswers from "./data/faqs"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/animate.min.css';
import './css/style.css';

import U01 from "./images/nft/01.png"
import U02 from "./images/nft/09.png"
import U03 from "./images/nft/11.png"
import U04 from "./images/nft/03.png"
import U05 from "./images/nft/02.png"
import U06 from "./images/nft/04.png"

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ease: "none"});

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);
  
  const [ scrolled, setScrolled ] = useState();
  const classes = classNames('App', {
    scrolled: scrolled,
  })
  useEffect(_ => {
    const handleScroll = _ => { 
      if (window.pageYOffset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  gsap.utils.toArray('.gs-gallery').forEach((section, index) => {
    const w = section.querySelector('.wrapper');
    const [x, xEnd] = (index % 2) ? [0, (w.scrollWidth - section.offsetWidth) * -0.1] : [w.scrollWidth * -0.1, 0];
    gsap.fromTo(w, {  x  }, {
      x: xEnd,
      scrollTrigger: { 
        trigger: section, 
        scrub: 2 
      }
    });
  });

  return (
    <div className={classes}>
      <header className="page-header">
        <div className='logo-wrapper'>
          <div className='app-name'>
            <span>All Star 2022</span>
          </div>
        </div>
        <div className='page-nav'>
          <ul className='social-icons'>
            <li>
              <a className='s-opensea' href="https://opensea.io/collection/all-star-2022" target="_blank" rel="noreferrer" title="Opensea">
                <i className='qatar-icon-opensea'></i>
              </a>
            </li>
            <li>
              <a className='s-discord' href="https://discord.gg/9cQhYNKgJR" target="_blank" rel="noreferrer" title="Discord">
                <i className='qatar-icon-discord'></i>
              </a>
            </li>
          </ul>
        </div>
      </header>
      <div className='section sec-aboutus'>
        <div className='container'>
          <h2 className='sec-title'><span>BACKGROUNDS</span></h2>
          <p>
            The <strong>2022 FIFA World Cup</strong> was an international football tournament contested <br/>by the men's national teams of FIFA's member associations and <strong>22nd edition</strong> of the FIFA World Cup.<br/><br/>
            It took place in <strong>Qatar</strong> from <strong>20 November to 18 December 2022</strong>, <br/>making it the first World Cup held in the Arab world and Muslim world, <br/> and the second held entirely in Asia after the 2002 tournament in South Korea and Japan.<br/><br/>
            We've created a genuine collection of NFTs for the best players of the 2022 FIFA World Cup with the love of football and NFTs.
          </p>
        </div>
      </div>
      <div className='section sec-collection'>
        <div className='container'>
          <h2 className='sec-title'><span>NFT COLLECTION</span></h2>
          <div className='sec-inner'>
            <div className='nft-collection'>
              <div className='gs-gallery'>
                <ul className='wrapper'>
                  <li><img src={U01} alt="NFT #1" /></li>
                  <li><img src={U02} alt="NFT #2" /></li>
                  <li><img src={U03} alt="NFT #3" /></li>
                  <li><img src={U04} alt="NFT #4" /></li>
                  <li><img src={U05} alt="NFT #5" /></li>
                  <li><img src={U06} alt="NFT #6" /></li>
                </ul>
              </div>
            </div>
          </div>

          <div className='sec-collection-link'>
            <a 
              href="https://opensea.io/collection/all-star-2022" 
              className='btn btn-collection'
              target="_blank"
              rel="noreferrer"
            >
              <span>See the collection</span>
            </a>
          </div>

        </div>
      </div>
      <div className='section sec-faq'>
        <div className='container'>
          <h2 className='sec-title'><span>FUN FACTS</span></h2>
          <FAQs questionsAnswers={questionsAnswers} />
        </div>
      </div>

      <div className='section sec-aboutus'>
        <div className='container'>
          <p>
            <strong>Disclaimer:</strong> This artwork is not affiliated with or endorsed by <strong>FIFA</strong> in any way. <br/>
            <strong>FIFA</strong> is a registered trademark of the Fédération Internationale de Football Association.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;